import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import bannerImage from '../assets/SLUG_BANNER-1689166193462.png'; // Importe a imagem corretamente

function HeroSection() {
  // Função para rolar suavemente para a seção de App Promotion
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' }); // Rola suavemente até a seção
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(${bannerImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Container maxWidth="md">
        <Typography 
          variant="h2" 
          gutterBottom 
          sx={{ fontWeight: 'bold', textShadow: '2px 2px 10px rgba(0, 0, 0, 0.7)' }}
        >
          Transforme Seu Corpo e Sua Vida com Best Trainer
        </Typography>

        <Typography variant="h6" paragraph sx={{ mb: 4, lineHeight: 1.6 }}>
          Acompanhe seus treinos, visualize vídeos de exercícios, e alcance seus objetivos de forma eficiente e personalizada.  
          Baixe nosso app e descubra como é fácil melhorar sua saúde com a orientação certa, onde quer que você esteja!
        </Typography>

        <Button
          onClick={() => scrollToSection('app-promotion-section')} // Rola para a seção
          variant="contained"
          sx={{
            backgroundColor: '#C0A15A',
            color: 'black',
            fontWeight: 'bold',
            borderRadius: '25px',
            padding: '10px 30px',
            textTransform: 'uppercase',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: '#b5914e',
              transform: 'scale(1.05)',
            },
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
          }}
        >
          Conheça o App
        </Button>
      </Container>
    </Box>
  );
}

export default HeroSection;
