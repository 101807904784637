import React from 'react';
import { Box, Typography, Container, Button } from '@mui/material';

function ContactSection() {
  return (
    <Box
      sx={{
        py: 8,
        backgroundColor: '#1A1A1A',
        color: 'white',
        textAlign: 'center',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.8)', // Sombra suave para destacar a seção
      }}
    >
      <Container maxWidth="md">
        <Typography 
          variant="h4" 
          gutterBottom 
          sx={{ color: '#C0A15A', fontWeight: 'bold' }} // Título com cor dourada
        >
          Entre em Contato
        </Typography>

        <Typography variant="body1" paragraph>
          Dúvidas? Entre em contato conosco e nossa equipe estará pronta para ajudar.
        </Typography>

        <Button
          variant="contained"
          sx={{
            backgroundColor: '#C0A15A', // Botão com cor dourada
            color: 'black',
            borderRadius: '25px',
            padding: '10px 20px',
            fontWeight: 'bold',
            mt: 2,
            '&:hover': {
              backgroundColor: '#b5914e', // Cor mais escura ao passar o mouse
            },
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)', // Sombra no botão
          }}
        >
          Fale Conosco
        </Button>
      </Container>
    </Box>
  );
}

export default ContactSection;
