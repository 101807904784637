import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Container } from '@mui/material';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';

const features = [
  {
    icon: <FitnessCenterIcon sx={{ fontSize: 60, color: '#C0A15A' }} />,
    title: 'Avaliações Frequentes',
    description: 'Acompanhe seu progresso a cada 60 dias.',
  },
  {
    icon: <RestaurantIcon sx={{ fontSize: 60, color: '#C0A15A' }} />,
    title: 'Nutrição Personalizada',
    description: 'Dicas de nutrição adaptadas ao seu objetivo.',
  },
  {
    icon: <AppShortcutIcon sx={{ fontSize: 60, color: '#C0A15A' }} />,
    title: 'Aplicativo Exclusivo',
    description: 'Acesso ao seu treino na palma da mão.',
  },
];

function FeaturesSection() {
  return (
    <Box
      sx={{
        py: 10,
        backgroundColor: '#1A1A1A',
        color: 'white',
        textAlign: 'center',
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom sx={{ color: '#C0A15A', fontWeight: 'bold' }}>
          Nossos Benefícios
        </Typography>

        <Grid container spacing={4} justifyContent="center" sx={{ mt: 4 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  backgroundColor: '#333',
                  color: 'white',
                  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.8)',
                  borderRadius: '15px',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.9)',
                  },
                }}
              >
                <CardContent sx={{ textAlign: 'center' }}>
                  {feature.icon}
                  <Typography variant="h6" gutterBottom sx={{ mt: 2, fontWeight: 'bold' }}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2">{feature.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default FeaturesSection;
