import React from 'react';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import FeaturesSection from './components/FeaturesSection';
import TestimonialsSection from './components/TestimonialsSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import AppPromotionSection from './components/AppPromotionSection';
import './styles.css'; // Arquivo de estilos personalizados

function App() {
  return (
    <>
      <Header />
      <div id="hero-section">
        <HeroSection />
      </div>
      <div id="features-section">
        <FeaturesSection />
      </div>
      <div id="testimonials-section">
        <TestimonialsSection />
      </div>
      <div id="app-promotion-section">
        <AppPromotionSection />
      </div>
      <div id="contact-section">
        <ContactSection />
      </div>
      <Footer />
    </>
  );
}

export default App;
