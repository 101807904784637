import React from 'react';
import { AppBar, Toolbar, Button, Box, IconButton, Container } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import logo from '../assets/logo-best.png'; // Importando a logo corretamente

function Header() {
  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: '#FFFFFF', // Fundo branco para visual mais leve
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Sombra suave
        padding: '10px 0',
      }}
    >
      <Container maxWidth="lg">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          
          {/* Logo Centralizada */}
          <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
            <img 
              src={logo} 
              alt="Best Trainer Logo" 
              style={{ 
                width: 80, // Tamanho menor para se encaixar no layout
                filter: 'invert(0)', // Sem inversão (logo preta para fundo branco)
              }} 
            />
          </Box>

          {/* Ícones das lojas e Botão de Planos */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <IconButton
              sx={{
                color: '#C0A15A',
                '&:hover': { color: '#b5914e' },
              }}
            >
              <AppleIcon sx={{ fontSize: 28 }} />
            </IconButton>

            <IconButton
              sx={{
                color: '#C0A15A',
                '&:hover': { color: '#b5914e' },
              }}
            >
              <AndroidIcon sx={{ fontSize: 28 }} />
            </IconButton>

            <Button
              variant="contained"
              sx={{
                backgroundColor: '#C0A15A',
                color: 'black',
                fontWeight: 'bold',
                borderRadius: '30px',
                padding: '8px 24px',
                textTransform: 'uppercase',
                '&:hover': {
                  backgroundColor: '#b5914e',
                },
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)', // Sombra leve
              }}
            >
              Planos
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
