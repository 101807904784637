import React from 'react';
import { Box, Typography, Container, Paper, Grid } from '@mui/material';

function TestimonialsSection() {
  const testimonials = [
    { 
      name: 'Diego', 
      text: 'O app Best Trainer me ajudou a manter a disciplina e alcançar meus objetivos rapidamente!' 
    },
    { 
      name: 'Lucirlene', 
      text: 'Com o aplicativo, consegui organizar meus treinos e melhorar meu desempenho a cada dia!' 
    },
    { 
      name: 'Maksuelly', 
      text: 'A personalização dos treinos e as avaliações periódicas foram fundamentais para o meu progresso.' 
    },
    { 
      name: 'Maria Luiza', 
      text: 'Nunca me senti tão motivada para treinar! O app mudou a minha rotina de forma positiva.' 
    },
  ];

  return (
    <Box sx={{ py: 8, backgroundColor: '#fff', color: 'white' }}>
      <Container maxWidth="lg">
        <Typography 
          variant="h4" 
          align="center" 
          gutterBottom 
          sx={{ color: '#C0A15A', fontWeight: 'bold' }}
        >
          O que nossos alunos dizem
        </Typography>
       

        <Grid container spacing={4}>
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Paper
                sx={{
                  padding: 4,
                  backgroundColor: '#1A1A1A',
                  color: 'white',
                  borderRadius: '10px',
                  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.8)',
                }}
              >
                <Typography 
                  variant="body1" 
                  gutterBottom 
                  sx={{ fontStyle: 'italic' }}
                >
                  "{testimonial.text}"
                </Typography>
                <Typography 
                  variant="subtitle2" 
                  align="right" 
                  sx={{ fontWeight: 'bold', mt: 2 }}
                >
                  - {testimonial.name}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default TestimonialsSection;
