import React from 'react';
import { Box, Typography, Container, Fab } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Ícone do WhatsApp

function Footer() {
  return (
    <>
      {/* Footer */}
      <Box sx={{ backgroundColor: '#000', color: 'white', py: 4, textAlign: 'center' }}>
        <Container maxWidth="lg">
          <Typography variant="body2" gutterBottom>
            © 2024 Best Trainer - Todos os direitos reservados.
          </Typography>
          <Typography variant="body2">Desenvolvido por ATHIX</Typography>
        </Container>
      </Box>

      {/* Ícone Flutuante do WhatsApp */}
      <Fab
        color="success"
        aria-label="whatsapp"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          backgroundColor: '#25D366', // Cor do WhatsApp
          '&:hover': { backgroundColor: '#1EBE54' }, // Cor mais escura no hover
        }}
        href="https://wa.me/SEU_NUMERO" // Substitua 'SEU_NUMERO' pelo número de WhatsApp
        target="_blank"
        rel="noopener noreferrer"
      >
        <WhatsAppIcon sx={{ fontSize: 30, color: 'white' }} />
      </Fab>
    </>
  );
}

export default Footer;
