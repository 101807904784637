import React from 'react';
import { Box, Typography, Button, Container, Grid } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import LanguageIcon from '@mui/icons-material/Language'; // Ícone para PWA
import appImage from '../assets/app-amostra.png'; // Importando a imagem corretamente

function AppPromotionSection() {
  return (
    <Box
      sx={{
        backgroundColor: '#FAF3E0', // Fundo claro e agradável
        py: 8,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          {/* Texto e Botões à Esquerda */}
          <Grid item xs={12} md={6}>
            <Typography 
              variant="h4" 
              gutterBottom 
              sx={{ fontWeight: 'bold', color: '#333' }}
            >
              Baixe o Best Trainer App
            </Typography>
            <Typography variant="body1" paragraph sx={{ color: '#555' }}>
              Acompanhe seus treinos, veja vídeos de execução dos exercícios, monitore seu progresso e acesse treinos personalizados.  
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 2 }}>
              Disponível para Android e iOS!
            </Typography>

            {/* Botões de Download */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                startIcon={<AndroidIcon />}
                sx={{
                  backgroundColor: '#000',
                  color: 'white',
                  '&:hover': { backgroundColor: '#333' },
                  padding: '10px 20px',
                }}
                href="#" // Substitua pelo link do Google Play
              >
                Google Play
              </Button>

              <Button
                variant="contained"
                startIcon={<AppleIcon />}
                sx={{
                  backgroundColor: '#000',
                  color: 'white',
                  '&:hover': { backgroundColor: '#333' },
                  padding: '10px 20px',
                }}
                href="#" // Substitua pelo link da App Store
              >
                App Store
              </Button>

              
            </Box>
          </Grid>

          {/* Imagem do Aplicativo à Direita */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img
                src={appImage} // Usando a imagem importada corretamente
                alt="Best Trainer App"
                style={{ maxWidth: '100%', height: 'auto', borderRadius: '20px' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default AppPromotionSection;
